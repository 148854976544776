<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 추천 상품 노출 - 레이아웃 설정 -->
    <section id="modalResultLayout" class="layer_dialog">
      <h2 class="mb20">추천 상품 노출 - 레이아웃 설정</h2>

      <div class="goods_layout">
        <ul>
          <li>
            <input
              type="radio"
              name="goodsLayout"
              value="1"
              v-model="layout_type"
              id="goodsLayout_1"
              checked
              @change="radioChange()"
            /><label for="goodsLayout_1">1단 형식 (가로형) </label>
            <ul v-if="layout_type === '1'" class="layout_group">
              <li>
                <div class="goods_info">
                  <p v-if="layout_type1" class="new_thumb">
                    <img
                      v-if="layout_type1"
                      :src="layout_type1"
                      :style="layout_image_style"
                    />
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <p v-else class="thumb">
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <dl>
                    <dt>
                      {{ layout_type1_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 1)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <input
                        type="checkbox"
                        name=""
                        v-model="result.is_price_show"
                        id="price_1"
                      /><label for="price_1">판매가격 노출</label>
                      <ul class="btn_g">
                        <li>
                          <button
                            type="button"
                            @click="
                              showModal('ModalQnaResultRecommendItemInfo')
                            "
                            class="btn btn_small btn_secondary"
                          >
                            <span>추천 상품 저장된 노출항목 불러오기</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            @click="showModal('ModalQnaResultRecommendButton')"
                            class="btn btn_small btn_secondary"
                          >
                            <span>버튼 설정</span>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </li>
          <li class="last">
            <input
              type="radio"
              name="goodsLayout"
              value="4"
              v-model="layout_type"
              id="goodsLayout_4"
              @change="radioChange()"
            /><label for="goodsLayout_4">1단 형식 (세로형)</label>
            <ul v-if="layout_type === '4'" class="layout_group">
              <li>
                <div class="goods_info">
                  <p v-if="layout_type1" class="new_thumb">
                    <img
                      v-if="layout_type1"
                      :src="layout_type1"
                      :style="layout_image_style3"
                    />
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <p v-else class="thumb">
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <dl>
                    <dt>
                      {{ layout_type1_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 1)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <input
                        type="checkbox"
                        name=""
                        v-model="result.is_price_show"
                        id="price_1"
                      /><label for="price_1">판매가격 노출</label>
                      <ul class="btn_g">
                        <li>
                          <button
                            type="button"
                            @click="
                              showModal('ModalQnaResultRecommendItemInfo')
                            "
                            class="btn btn_small btn_secondary"
                          >
                            <span>추천 상품 저장된 노출항목 불러오기</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            @click="showModal('ModalQnaResultRecommendButton')"
                            class="btn btn_small btn_secondary"
                          >
                            <span>버튼 설정</span>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li></li>
              <li></li>
            </ul>
          </li>
          <li>
            <input
              type="radio"
              name="goodsLayout"
              value="2"
              v-model="layout_type"
              id="goodsLayout_2"
              @change="radioChange()"
            /><label for="goodsLayout_2">2단 형식 (가로형)</label>
            <ul v-if="layout_type === '2'" class="layout_group">
              <li>
                <div class="goods_info">
                  <p v-if="layout_type1" class="new_thumb">
                    <img
                      v-if="layout_type1"
                      :src="layout_type1"
                      :style="layout_image_style"
                    />
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <p v-else class="thumb">
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>
                  <dl>
                    <dt>
                      {{ layout_type1_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 1)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <input
                        type="checkbox"
                        name=""
                        id="price_1"
                        v-model="result.is_price_show"
                      /><label for="price_1">판매가격 노출</label>
                      <ul class="btn_g">
                        <li>
                          <button
                            type="button"
                            @click="
                              showModal('ModalQnaResultRecommendItemInfo')
                            "
                            class="btn btn_small btn_secondary"
                          >
                            <span>추천 상품 저장된 노출항목 불러오기</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            @click="showModal('ModalQnaResultRecommendButton')"
                            class="btn btn_small btn_secondary"
                          >
                            <span>버튼 설정</span>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="goods_info">
                  <p v-if="layout_type2" class="new_thumb">
                    <img
                      v-if="layout_type2"
                      :src="layout_type2"
                      :style="layout_image_style"
                    />
                  </p>
                  <p v-else class="thumb"></p>

                  <dl>
                    <dt>
                      {{ layout_type2_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 2)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <p class="txt">
                        레이아웃 형태만 참고해주세요.<br />좌측의 상품 노출
                        관련한 설정을<br />하시면 동일하게 반영됩니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </li>
          <li class="last">
            <input
              type="radio"
              name="goodsLayout"
              value="5"
              v-model="layout_type"
              id="goodsLayout_5"
              @change="radioChange()"
            /><label for="goodsLayout_5">2단 형식 (세로형)</label>
            <ul v-if="layout_type === '5'" class="layout_group">
              <li>
                <div class="goods_info">
                  <p v-if="layout_type1" class="new_thumb">
                    <img
                      v-if="layout_type1"
                      :src="layout_type1"
                      :style="layout_image_style3"
                    />
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <p v-else class="thumb">
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <dl>
                    <dt>
                      {{ layout_type1_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 1)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <input
                        type="checkbox"
                        name=""
                        v-model="result.is_price_show"
                        id="price_1"
                      /><label for="price_1">판매가격 노출</label>
                      <ul class="btn_g">
                        <li>
                          <button
                            type="button"
                            @click="
                              showModal('ModalQnaResultRecommendItemInfo')
                            "
                            class="btn btn_small btn_secondary"
                          >
                            <span>추천 상품 저장된 노출항목 불러오기</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            @click="showModal('ModalQnaResultRecommendButton')"
                            class="btn btn_small btn_secondary"
                          >
                            <span>버튼 설정</span>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="goods_info">
                  <p v-if="layout_type2" class="new_thumb">
                    <img
                      v-if="layout_type2"
                      :src="layout_type2"
                      :style="layout_image_style3"
                    />
                  </p>
                  <p v-else class="thumb"></p>

                  <dl>
                    <dt>
                      {{ layout_type2_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 2)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <p class="txt">
                        레이아웃 형태만 참고해주세요.<br />좌측의 상품 노출
                        관련한 설정을<br />하시면 동일하게 반영됩니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
              <li></li>
            </ul>
          </li>
          <li class="last">
            <input
              type="radio"
              name="goodsLayout"
              value="3"
              v-model="layout_type"
              id="goodsLayout_3"
              @change="radioChange()"
            /><label for="goodsLayout_3">3단 형식</label>
            <ul v-if="layout_type === '3'" class="layout_group">
              <li>
                <div class="goods_info">
                  <p v-if="layout_type1" class="new_thumb">
                    <img
                      v-if="layout_type1"
                      :src="layout_type1"
                      :style="layout_image_style3"
                    />
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <p v-else class="thumb">
                    <button
                      type="button"
                      @click="showModal('ModalQnaResultFont')"
                      class="btn btn_small btn_secondary open_layer ml5"
                    >
                      <span>폰트/이미지 설정</span>
                    </button>
                  </p>

                  <dl>
                    <dt>
                      {{ layout_type1_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 1)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <input
                        type="checkbox"
                        name=""
                        v-model="result.is_price_show"
                        id="price_1"
                      /><label for="price_1">판매가격 노출</label>
                      <ul class="btn_g">
                        <li>
                          <button
                            type="button"
                            @click="
                              showModal('ModalQnaResultRecommendItemInfo')
                            "
                            class="btn btn_small btn_secondary"
                          >
                            <span>추천 상품 저장된 노출항목 불러오기</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            @click="showModal('ModalQnaResultRecommendButton')"
                            class="btn btn_small btn_secondary"
                          >
                            <span>버튼 설정</span>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="goods_info">
                  <p v-if="layout_type2" class="new_thumb">
                    <img
                      v-if="layout_type2"
                      :src="layout_type2"
                      :style="layout_image_style3"
                    />
                  </p>
                  <p v-else class="thumb"></p>

                  <dl>
                    <dt>
                      {{ layout_type2_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 2)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <p class="txt">
                        레이아웃 형태만 참고해주세요.<br />좌측의 상품 노출
                        관련한 설정을<br />하시면 동일하게 반영됩니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="goods_info">
                  <p v-if="layout_type3" class="new_thumb">
                    <img
                      v-if="layout_type3"
                      :src="layout_type3"
                      :style="layout_image_style3"
                    />
                  </p>
                  <p v-else class="thumb"></p>

                  <dl>
                    <dt>
                      {{ layout_type3_product_name }}
                      <button
                        type="button"
                        @click="showModal('ModalQnaResultGoods', 3)"
                        class="btn btn_small btn_primary"
                      >
                        <span>추천 상품 설정</span>
                      </button>
                    </dt>
                    <dd>
                      <p class="txt">
                        레이아웃 형태만 참고해주세요.<br />좌측의 상품 노출
                        관련한 설정을<br />하시면 동일하게 반영됩니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품 노출 - 레이아웃 설정 -->
  </vue-final-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    layout_type: '1',
    goods_ids: [],
    result: {
      recommend_goods: [],
    },
    layout_type1: null,
    layout_type2: null,
    layout_type3: null,
    layout_type1_product_name: '상품명',
    layout_type2_product_name: '상품명',
    layout_type3_product_name: '상품명',
    layout_image_style: 'width: 118px; height: 118px;',
    layout_image_style3: 'width: 220px; height: 220px;',
    desktop_items: [],
    mobile_items: [],
  }),
  props: [
    'item',
    'type',
    'result_desktop_items',
    'result_mobile_items',
    'survey_config_info',
  ],
  created: async function () {
    this.init();
  },
  methods: {
    init: function () {
      if (this.item !== null) {
        this.result = this.item.recommend_goods.info;
        this.layout_type = this.result.layout_type;
      } else {
        this.result.layout_type = '1';
        this.result.is_price_show = true;

        if (this.result.recommend_goods.length === 0) {
          this.result.recommend_goods.push({ type: '', rank: '', list: [] });
          this.result.recommend_goods.push({ type: '', rank: '', list: [] });
          this.result.recommend_goods.push({ type: '', rank: '', list: [] });
        }
      }
      this.setProductImage(this.result);
    },
    setResult: function () {
      this.result.layout_type = this.layout_type;

      this.desktop_items = this.result_desktop_items;
      this.mobile_items = this.result_mobile_items;

      // 각 레이아웃 타입에 따라 데이터 초기화
      if (this.result.layout_type === '1' || this.result.layout_type === '4') {
        this.result.recommend_goods[1].list = [];
        this.result.recommend_goods[2].list = [];
      } else if (
        this.result.layout_type === '2' ||
        this.result.layout_type === '5'
      ) {
        this.result.recommend_goods[2].list = [];
      }

      const params = {
        type: 'recommend_goods',
        info: this.result,
      };

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.desktop_items.splice(this.item.index, 1, params);
        } else {
          this.mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.desktop_items.push(params);
        } else {
          this.mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.desktop_items);
      this.$emit('updateList', 'mobile', this.mobile_items);

      this.show_modal = false;
    },
    showModal(id, index) {
      if (id === undefined || id === '' || id === null) {
        return false;
      }
      this.$vfm.show({
        component: defineAsyncComponent(() =>
          import(`@/components/qna/modal/${id}.vue`),
        ),
        bind: {
          survey_config_info: this.survey_config_info,
          result: this.result,
          type: this.type,
          index: index,
          layout_type: this.layout_type,
        },
        on: {
          updateData: (data, index) => {
            this.setProductImage(data);
          },
        },
      });
    },
    setProductImage: function (data) {
      if (data && data.recommend_goods && data.recommend_goods.length > 0) {
        for (let i = 0; i < data.recommend_goods.length; i++) {
          let _layout_type = this.layout_type;
          if (this.layout_type == 4) {
            _layout_type = 1;
          }
          if (this.layout_type == 5) {
            _layout_type = 2;
          }
          if (i >= _layout_type) {
            break;
          }

          if (this.layout_type == 1 || this.layout_type == 4) {
            if (data.recommend_goods[i].list.length == 0) {
              this.layout_type1 = null;
              this.layout_type1_product_name = '상품명';
            }
          }
          if (this.layout_type == 2 || this.layout_type == 5) {
            if (data.recommend_goods[i].list.length == 0) {
              if (i == 0) {
                this.layout_type1 = null;
                this.layout_type1_product_name = '상품명';
              }

              if (i == 1) {
                this.layout_type2 = null;
                this.layout_type2_product_name = '상품명';
              }
            }
          }

          if (this.layout_type == 3) {
            if (data.recommend_goods[i].list.length == 0) {
              if (i == 0) {
                this.layout_type1 = null;
                this.layout_type1_product_name = '상품명';
              }

              if (i == 1) {
                this.layout_type2 = null;
                this.layout_type2_product_name = '상품명';
              }
              if (i == 2) {
                this.layout_type3 = null;
                this.layout_type3_product_name = '상품명';
              }
            }
          }
          if (
            data.recommend_goods[i] &&
            data.recommend_goods[i].list &&
            data.recommend_goods[i].list.length > 0
          ) {
            for (let j = 0; j < data.recommend_goods[i].list.length; j++) {
              if (this.layout_type == 1 || this.layout_type == 4) {
                this.layout_type1 = data.recommend_goods[i].list[j].image_url;
                this.layout_type1_product_name = this.textLength(
                  data.recommend_goods[i].list[j].product_name,
                  7,
                );
              }
              if (this.layout_type == 2 || this.layout_type == 5) {
                if (i == 0) {
                  this.layout_type1 = data.recommend_goods[i].list[j].image_url;
                  this.layout_type1_product_name = this.textLength(
                    data.recommend_goods[i].list[j].product_name,
                    7,
                  );
                }
                if (i == 1) {
                  this.layout_type2 = data.recommend_goods[i].list[j].image_url;
                  this.layout_type2_product_name = this.textLength(
                    data.recommend_goods[i].list[j].product_name,
                    7,
                  );
                }
              }
              if (this.layout_type == 3) {
                if (i == 0) {
                  this.layout_type1 = data.recommend_goods[i].list[j].image_url;
                  this.layout_type1_product_name = this.textLength(
                    data.recommend_goods[i].list[j].product_name,
                    7,
                  );
                }
                if (i == 1) {
                  this.layout_type2 = data.recommend_goods[i].list[j].image_url;
                  this.layout_type2_product_name = this.textLength(
                    data.recommend_goods[i].list[j].product_name,
                    7,
                  );
                }
                if (i == 2) {
                  this.layout_type3 = data.recommend_goods[i].list[j].image_url;
                  this.layout_type3_product_name = this.textLength(
                    data.recommend_goods[i].list[j].product_name,
                    7,
                  );
                }
              }
            }
          }
        }
      }
    },
    textLength: function (text, length) {
      if (!length || length == 0) {
        length = 7;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
    radioChange: function () {
      if (Number(this.layout_type) == 1 || Number(this.layout_type) == 4) {
        this.result.recommend_goods[1].list = [];
        this.result.recommend_goods[2].list = [];
        this.result.recommend_goods[1].type = 'none';
        this.result.recommend_goods[2].type = 'none';
        this.result.recommend_goods[1].rank = '';
        this.result.recommend_goods[2].rank = '';
      }
      if (Number(this.layout_type) == 2 || Number(this.layout_type) == 5) {
        this.result.recommend_goods[2].list = [];
        this.result.recommend_goods[2].type = 'none';
        this.result.recommend_goods[2].rank = '';
      }
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.new_thumb {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 1px #aaa solid;
  margin: auto;
}
.goods_layout .goods_info .new_thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
}

.goods_layout .goods_info .new_thumb::before,
.goods_layout .goods_info .new_thumb::after {
  height: 168px;
  margin-top: -84px;
}
.new_thumb::before {
  transform: rotate(45deg);
}
.new_thumb::before,
.new_thumb::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 82px;
  background-color: #aaa;
  margin-top: -41px;
}
.goods_layout .goods_info .new_thumb::before,
.goods_layout .goods_info .new_thumb::after {
  height: 168px;
  margin-top: -84px;
}
.new_thumb::after {
  transform: rotate(-45deg);
}
.new_thumb::before,
.new_thumb::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 82px;
  background-color: unset;
  margin-top: -41px;
}

.goods_layout .goods_info .new_thumb .btn {
  position: absolute;
  bottom: 10px;
  left: 11px;
  padding: 0 5px;
  letter-spacing: -0.075em;
  z-index: 2;
}

.goods_layout > ul > li.last .goods_info .new_thumb {
  bottom: 10px;
  width: 222px;
  height: 222px;
  margin: 0;
}

.padding_left_0 {
  padding-left: 0px;
}
</style>
